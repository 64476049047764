import { values, includes, trimStart } from 'lodash';
import { getTopology } from '../store/topology/topology-selectors';
import { INSTANCE_ID_WIX } from '@wix/communities-universal/dist/src/constants/wix-blogs';
import { isSite } from '../store/basic-params/basic-params-selectors';
import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { SECTION_BLOG_POST_PAGE } from '@wix/communities-blog-client-common';
import Wix from '../services/wix-sdk-polyfill';

export const navigateWithinBlog = (path) => (dispatch, getState, { wixCodeApi }) => {
  const { baseUrl, sectionUrl } = getTopology(getState());
  const sectionPath = sectionUrl.replace(baseUrl, '');
  return wixCodeApi.location.to(`${sectionPath}${path}`);
};

export const navigateWithinPostPage = (path) => (dispatch, getState, { wixCodeApi }) => {
  const { baseUrl, postPageSectionUrl: sectionUrl } = getTopology(getState());
  const sectionPath = sectionUrl.replace(baseUrl, '');
  return wixCodeApi.location.to(`${sectionPath}${path}`);
};

export const navigateProGalleryWithinPostPage = (path) => (dispatch, getState, { wixCodeApi, appParams }) => {
  const state = getState();
  const { baseUrl, postPageSectionUrl: sectionUrl } = getTopology(state);
  if (!isSite(state)) {
    return Wix.Utils.navigateToSection(
      {
        appDefinitionId: BLOG_APP_ID,
        sectionId: SECTION_BLOG_POST_PAGE,
        shouldRefreshIframe: false,
      },
      trimStart(path, '/'),
    );
  }

  // Temporary hack for marketing sites, because wix code navigation doesn't work properly
  if (includes(values(INSTANCE_ID_WIX), appParams.instanceId)) {
    return wixCodeApi.location.to(`${sectionUrl}${path}`);
  }

  const sectionPath = sectionUrl.replace(baseUrl, '');
  return wixCodeApi.location.to(`${sectionPath}${path}`);
};
