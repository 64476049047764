import { get } from 'lodash';
import { EXPERIMENT_ARCHIVE_PAGE_SEO } from '@wix/communities-blog-experiments';
import { isExperimentEnabled, SECTION_CATEGORY } from '@wix/communities-blog-client-common';

import { fetchArchive } from '../../../common/actions/fetch-archive';
import { isSite, isSSR } from '../../../common/store/basic-params/basic-params-selectors';
import { pageOpened } from '../../../common/actions/page-opened';
import { ARCHIVE_PAGE } from '../../../common/services/detect-route';
import { setMetaTagRobotsNoIndex } from '../../../common/services/set-metatag-robots-noindex';
import { generateArchiveSEOTags } from '../../../common/services/generate-seo-tags/generate-archive-seo-tags';
import { getShowPagination } from '../../../common/selectors/pagination-selectors';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';

export const createArchivePageRouter = (store, wixCodeApi) => async ({ params }) => {
  !isSSR(store.getState()) && store.dispatch(pageOpened({ page: ARCHIVE_PAGE }));

  const { year, month, pageIndex } = params;
  if (year && month) {
    try {
      const posts = await store.dispatch(fetchArchive(year, month, pageIndex));

      const state = store.getState();
      const showPagination = getShowPagination(state, SECTION_CATEGORY);
      const page = parseInt(get(params, 'pageIndex', '1'), 10);
      const archiveSEOEnabled = isExperimentEnabled(state, EXPERIMENT_ARCHIVE_PAGE_SEO);
      if (isSite(state) && archiveSEOEnabled) {
        const sectionUrl = getSectionUrl(state);
        const archiveSEOTags = generateArchiveSEOTags({
          sectionUrl,
          showPagination,
          state,
          page,
          posts,
          locale: wixCodeApi.site.regionalSettings,
          year,
          month,
        });
        wixCodeApi.seo.renderSEOTags(archiveSEOTags);
      } else {
        setMetaTagRobotsNoIndex(wixCodeApi);
      }

      return posts;
    } catch (error) {
      throw error;
    }
  }
};
