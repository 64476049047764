import { getInstance } from '../../../common/controller/helpers';
import { fetchExperimentsSuccess } from '../../../common/store/experiments/experiments-actions';

const BUNDLE_NAME = 'wix-comments-controller';
let hasInitialized;

export async function initWixCommentsController(
  { config, setProps, wixCodeApi, platformAPIs, type, compId },
  { isPreview, isEditor, staticsBaseUrl },
) {
  if (hasInitialized) {
    return;
  }

  let initializeCommentsController;
  if (isPreview || isEditor) {
    initializeCommentsController = (
      await import(/* webpackChunkName: "wix-comments-controller-chunk" */ './wix-comments-controller')
    ).default;
  } else {
    importScripts(staticsBaseUrl + `./${BUNDLE_NAME}.bundle.min.js`);
    initializeCommentsController = global[BUNDLE_NAME].default;
  }

  hasInitialized = true;

  return initializeCommentsController({ config, setProps, wixCodeApi, platformAPIs, type, compId });
}

export async function conductCommentsExperiment({ wixCodeApi, store }) {
  try {
    const instance = getInstance(wixCodeApi)();
    const url = `/_serverless/comments-blog-integration/enabled`;
    const isEnabled = await fetch(url, { headers: { Authorization: instance } }).then((res) => res.json());
    store.dispatch(fetchExperimentsSuccess({ isWixCommentsEnabled: `${isEnabled}` }));
  } catch (error) {
    store.dispatch(fetchExperimentsSuccess({ isWixCommentsEnabled: 'true' }));
  }
}
